<template>
    <div class="card card-custom gutter-b">
        <div class="card-header card-header-tabs-line">
            <div class="card-toolbar">
                <ul class="nav nav-tabs nav-bold nav-tabs-line">
                    <li class="nav-item" @click="activeTab(1)" style="cursor: grab;">
                        <a class="nav-link" v-bind:class="{active : tab_active1}" data-toggle="tab">
                            <span class="nav-icon"><i class="flaticon2-user"></i></span>
                            <span class="nav-text">Sửa tài khoản</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="activeTab(2)" style="cursor: grab;">
                        <a class="nav-link" v-bind:class="{active : tab_active2}" data-toggle="tab">
                            <span class="nav-icon"><i class="flaticon-edit"></i></span>
                            <span class="nav-text">Đổi mật khẩu</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body">
            <div class="tab-content">
                <div class="tab-pane fade" v-bind:class="{show : show_active_1, active :show_active_1}" role="tabpanel"
                     aria-labelledby="kt_tab_pane_1_4">
                    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                        <form class="form" @submit.prevent="handleSubmit(onSubmit)">
                            <div class="card card-custom">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4 ">
                                            <div class="form-group">
                                                <label>Họ tên
                                                    <span class="text-danger">*</span></label>
                                                <ValidationProvider vid="name" name="Họ tên" rules="required"
                                                                    v-slot="{ errors,classes }">
                                                    <input type="text" ref="name" name="name" class="form-control"
                                                           :class="classes"
                                                           placeholder="Họ tên"
                                                           v-model=user.name>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="col-md-4 ">
                                            <div class="form-group">
                                                <label>Email
                                                    <span class="text-danger">*</span></label>
                                                    <input disabled type="email" name="email" class="form-control"
                                                        placeholder="Email"
                                                        :class="classes"
                                                        v-model=user.email>
                                            </div>
                                        </div>
                                        <div class="col-md-4 ">
                                            <div class="form-group">
                                                <label>Số điện thoại
                                                    <span class="text-danger">*</span></label>
                                                <ValidationProvider name="Số điện thoại" vid="phone"
                                                                    :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                                                    v-slot="{ errors,classes }">
                                                    <input type="number" class="form-control" v-model="user.phone"

                                                           :class="classes">
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Loại tài khoản
                                                    <span class="text-danger">*</span></label>
                                                <ValidationProvider vid="account_type_id" name="Loại tài khoản"
                                                                    :rules="{ required: true }"
                                                                    v-slot="{ errors,classes }">
                                                    <el-select filterable class="w-100" placeholder="Loại tài khoản"
                                                               v-model="user.profile.account_type_id" clearable>
                                                        <el-option
                                                            v-for="item in accountType"
                                                            :key="item.account_type_id"
                                                            :label="item.name"
                                                            :value="item.account_type_id"
                                                        >
                                                            <span style="float: left">{{ item.name }}</span>
                                                        </el-option>
                                                    </el-select>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{
                                                                errors[0]
                                                            }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="showBusinessLevel">
                                            <div class="form-group">
                                                <label>Cấp bậc<span class="text-danger">*</span></label>
                                                <ValidationProvider vid="business_level_id" name="Cấp bậc"
                                                                    :rules="{ required: true }"
                                                                    v-slot="{ errors,classes }">
                                                    <el-select filterable class="w-100" placeholder="Cấp bậc"
                                                               v-model="user.profile.business_level_id"
                                                               clearable
                                                    >
                                                        <el-option
                                                            v-for="item in businessLevels"
                                                            :key="item.id"
                                                            :label="item.name"
                                                            :value="item.id"
                                                        >
                                                            <span style="float: left">{{ item.name }}</span>
                                                        </el-option>
                                                    </el-select>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{
                                                                errors[0]
                                                            }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Chọn nhóm quyền (Backend)  <span class="text-danger"></span></label>
                                                <el-select v-model="user.backend_role_id" clearable filterable
                                                           class="w-100" placeholder="Chọn quyền"
                                                           ref="backend_role_id">
                                                    <el-option
                                                        v-for="(item, key) in backend_roles"
                                                        :label="item.name"
                                                        :value="item.id"
                                                        :key="key"
                                                    >
                                                        <span style="float: left">{{ item.name }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="showKhuvuc">
                                            <div class="form-group">
                                                <label>Khu vực <span class="text-danger"></span></label>
                                                <ValidationProvider vid="area_id" name="Khu vực"
                                                                    :rules="{ required: true }"
                                                                    v-slot="{ errors,classes }">
                                                    <el-select @change="updateStateKhuvuc" filterable class="w-100"
                                                               placeholder="Chọn khu vực" v-model="user.profile.area_id"
                                                               clearable>
                                                        <el-option
                                                            v-for="item in areas"
                                                            :key="item.id"
                                                            :label="item.name"
                                                            :value="item.id"
                                                        >
                                                            <span style="float: left">{{ item.name }}</span>
                                                        </el-option>
                                                    </el-select>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{
                                                                errors[0]
                                                            }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Trung tâm <span class="text-danger"></span></label>
                                                <el-select @change="updateTrungTam" filterable class="w-100" placeholder="Chọn trung tâm" v-model="user.profile.trung_tam_id" clearable>
                                                    <el-option
                                                        v-for="item in centers"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                    >
                                                        <span style="float: left">{{ item.name }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Chọn chi nhánh <span class="text-danger"></span></label>
                                                <el-select filterable class="w-100" placeholder="Chọn chi nhánh" v-model="user.profile.chi_nhanh_id" clearable>
                                                    <el-option
                                                        v-for="item in branches"
                                                        :key="item.id"
                                                        :label="`${item.name_alias ? item.name_alias : item.name}`"
                                                        :value="item.id"
                                                    >
                                                        <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="showSelectBoss">
                                            <div class="form-group">
                                                <label>Chọn cấp trên <span class="text-danger"></span></label>
                                                <el-select @change="searchUserByBossCode" filterable class="w-100" placeholder="Chọn cấp trên" v-model="user.parent_id" clearable>
                                                    <el-option
                                                        v-for="item in maCapTren"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                    >
                                                        <span style="float: left">{{ item.name }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>

                                        <div class="col-md-4 ">
                                            <div class="form-group">
                                                <label>Chức vụ kiêm nhiệm  <span class="text-danger"></span></label>
                                                <el-select @change="updateStatePositions" multiple filterable class="w-100" placeholder="Chọn chức vụ" v-model="cachePosition" clearable>
                                                    <el-option
                                                        v-for="item in positions"
                                                        :key="item.id"
                                                        :label="item.position"
                                                        :value="item.position"
                                                    >
                                                        <span style="float: left">{{ item.position }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4 ">
                                            <div class="form-group">
                                                <label>Chọn giới tính <span class="text-danger"></span></label>
                                                <el-select filterable class="w-100" placeholder="Chọn giới tính" v-model="user.profile.gender" clearable>
                                                    <el-option
                                                        v-for="item in gender"
                                                        :key="item"
                                                        :label="item"
                                                        :value="item"
                                                    > <span style="float: left">{{ item }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4 ">
                                            <div class="form-group mb-1">
                                                <label>Địa chỉ
                                                    <span class="text-danger"></span></label>
                                                <textarea class="form-control" rows="3"
                                                          v-model="user.profile.address"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Ngày tháng năm sinh
                                                    <span class="text-danger">*</span></label>
                                                <ValidationProvider vid="profile.birthday" name="Ngày sinh"
                                                                    rules="required"
                                                                    v-slot="{ errors,classes }">
                                                    <date-picker v-model="user.profile.birthday" format="DD-MM-YYYY"
                                                                 valueType="YYYY-MM-DD" :class="classes"></date-picker>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!--end::Form-->
                                        </div>
                                        <div class="col-md-4 ">
                                            <div class="form-group">
                                                <label>Ngày bắt đâu làm việc
                                                    <span class="text-danger"></span></label>
                                                <ValidationProvider vid="profile.start_to_work" name="Ngày sinh"
                                                                    rules=""
                                                                    v-slot="{ errors,classes }">
                                                    <date-picker v-model="user.profile.start_to_work"
                                                                 format="DD-MM-YYYY"
                                                                 valueType="YYYY-MM-DD" :class="classes"></date-picker>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!--end::Form-->
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>
                                                    <span class="text-danger"></span></label>
                                                <div class="col-9 col-form-label">
                                                    <div class="checkbox-inline">
                                                        <label class="checkbox checkbox-success">
                                                            <input type="checkbox" name="Checkboxes5"
                                                                   v-model="user.status">
                                                            <span></span>Kích hoạt</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header py-3">
                                    <div class="card-title align-items-start flex-column">
                                        <div class="card-toolbar">
                                            <button type="submit" class="btn btn-success mr-2" :disabled="is_disable">
                                                Cập nhật
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
                <div class="tab-pane fade" v-bind:class="{show:show_active_2 , active : show_active_2}" role="tabpanel"
                     aria-labelledby="kt_tab_pane_2_4">
                    <!--Đổi mật khẩu-->
                    <div class="card card-custom">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mật khẩu mới
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider name="Mật khẩu" rules="required|min:6"
                                                            v-slot="{ errors,classes }">
                                            <input name="password" type="password" class="form-control"
                                                   placeholder="Mật khẩu"
                                                   v-model="user.password" :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nhập lại mật khẩu
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider name="Xác nhận mật khẩu"
                                                            rules="required|min:6|confirmed:Mật khẩu"
                                                            v-slot="{ errors,classes }">
                                            <input name="password_confirmation" type="password" class="form-control"
                                                   placeholder="Xác thực mật khẩu" v-model="user.confirm_password"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header py-3 float-left">
                        <div class="card-title align-items-start flex-column">
                        </div>
                        <div class="card-toolbar">
                            <button type="button" class="btn btn-success mr-2" @click="changePassword">
                                Đổi
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--begin::Card-->
    <!--end::Card-->
</template>
<script>
import {mapGetters} from "vuex";
import {provinces} from "../../../assets/js/components/provinces";
import {banks} from "../../../assets/js/components/banks";
import {branchs} from "../../../assets/js/components/branchs";
import {
    CHANGE_PASSWORD,
    GET_USER_BY_ID,
    SEARCH_BY_MA_CAP_TREN,
    UPDATE_STAFF
} from "../../../../src/core/services/store/user/users.module";
import Multiselect from 'vue-multiselect'
import BankAccountEdit from "./BankAccountEdit";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Select2Ajax from "../../content/Select2Ajax";
import Select2 from "../../content/Select2";
import {GET_LIST_PERMISSION} from "../../../core/services/ajaxUrl";
import Swal from "sweetalert2";
import {
    GET_ACCOUNT_TYPE_FOR_SELECT,
    GET_POSITIONS, GET_ROLE_BACKEND,
} from "../../../core/services/store/user/account-type.module";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_AREAS} from "../../../core/services/store/center/area.module";
import {GET_BRANCHES_LIST} from "../../../core/services/store/center/branch.module";
import {GET_CENTERS_FOR_NAME} from "../../../core/services/store/center/centers.module";
import moment from 'moment-timezone';
import {GET_BUSINESS_LEVEL} from "@/core/services/store/business/level.module";
import {GDKV, GDVP, HANH_CHINH_KHU_VUC, SALE_LEADER, SALE_MEMBER} from "@/core/option/accountTypeOption";

export default {
    name: "UserEdit",
    components: {
        BankAccountEdit, Multiselect, DatePicker, Select2, Select2Ajax,
    },
    data() {
        return {
            tab_active1: true,
            tab_active2: false,
            show_active_1: true,
            show_active_2: false,
            prevRoute: null,
            user: {
                name: '',
                phone: "",
                status: true,
                parent_id: "",
                password: "",
                confirm_password: "",
                positions: [],
                profile: {
                    gender: 'Nam',
                    address: "",
                    birthday: "",
                    start_to_work: "",
                    chi_nhanh_id: "",
                    trung_tam_id: "",
                    van_phong_id: "",
                    xac_thuc_sdt: "",
                    thoi_gian_nghi_viec: "",
                    account_type_id: '',
                    loai_tai_khoan: "",
                    user_update_role: '',
                    updated_role_at: ''
                },
                backend_role_id: '',
            },
            backend_role_id_default: '',
            /*Data default*/
            isLoadingBoss: false,
            keyword_search_boss_code: '',
            status: {
                0: 'Không hoạt động',
                1: 'Đang hoạt động',
                2: "Nghỉ việc"
            },
            gender: [
                'Nam', 'Nữ', 'Khác'
            ],
            boss_type_account: {
                1: 'Sale Leader',
                2: 'Sale Member'
            },
            branches: [],
            positions: [],
            provinces: [],
            backend_roles: [],
            maCapTren: [],
            branchs: {}, // Branh của tài khoản ngân hàng
            areas: [],
            centers: [],
            /*Cache */
            cacheBranch: [],
            cacheAccountType: [],
            cachePosition: [],
            cacheBank: [],
            accountType: [],
            cacheMaCapTren: [],
            cacheKhuvuc: [],
            cacheTrungTam: [],
            showKhuvuc: false,
            showSelectBoss: false,
            is_show_branch: false,
            showTrungTam: false,
            is_disable: false,
            businessLevels: [],
            showBusinessLevel: false
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    }
    ,
    computed: {
        getPermissionUrl: function () {
            return GET_LIST_PERMISSION
        },
        ...mapGetters(["currentUserAccountInfo", 'roles', 'currentUser'])
    },
    async created() {
        this.getRoles();
        await this.getAccountType();
        await this.getCenters();
        await this.getUserById();
        await this.getAreas();
        await this.getPositions();
        await this.checkShowBusinessLevel(this.user.profile.account_type_id);
        this.setDefault();
    },

    methods: {
        setDefault() {
            this.provinces = provinces
            this.banks = banks
            this.branchs = branchs
        },
        setBreadcrumb() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý tài khoản", route: 'profile-1'},
                {title: "Sửa tài khoản"}
            ]);
        },
        getRoles() {
            this.$store.dispatch(GET_ROLE_BACKEND).then((res) => {
                this.backend_roles = res.data;
            });
        },
        async getUserById() {
            let id = this.$route.params.id;
            if (id) {
                await this.$store.dispatch(GET_USER_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.backend_role_id_default = res.data.backend_role_id;
                        let user = res.data
                        this.user = user
                        let payload = { centers : this.user.profile.trung_tam_id }
                        this.handleCache(user)
                        this.checkShowKhuVuc(user)
                        this.getBranches(payload)
                    }
                });
            }
        },
        getBranches(payload) {
            this.$store.dispatch(GET_BRANCHES_LIST, payload)
                .then((res) => {
                if (!res.error) {
                    this.branches = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        async getCenters() {
            await this.$store.dispatch(GET_CENTERS_FOR_NAME).then((res) => {
                if (!res.error) {
                    this.centers = res.data
                }
            });
        },
        /*Thêm account*/
        async getAreas() {
            await this.$store.dispatch(GET_AREAS, {
                limit: 10000,
            }).then((res) => {
                if (!res.error) {
                    this.areas = res.data.data
                }
            });
        },
        addAccount() {
            this.user.bank_accounts.push({
                enable_branch_bank: false,
                owner_name: "",
                ngan_hang: "",
                chi_nhanh: "",
                stk: "",
                loai_tai_khoan: "",
                is_default: false,
            })
        },
        /*Xóa account*/
        minusAccount(index) {
            Swal.fire({
                title: "Bạn chắc chắn muốn xóa?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Không",
            }).then((result) => {
                if (result.isConfirmed) {
                    if (index > 0) {
                        this.user.bank_accounts.splice(index, 1)
                    }
                }
            });
        },
        /*Nếu là ngân hàng agribank thì cho chọn thành phố vào chi nhánh*/
        updateStateCenter(obj) {
            this.user.profile.trung_tam_id = obj.id
        },
        updateStateKhuvuc(obj) {
            this.user.profile.khuvuc_id = obj.id
        },
        updateTrungTam() {
            this.cacheBranch = '';
            this.user.profile.chi_nhanh_id = '';
            let centers = this.user.profile.trung_tam_id
            this.getBranches( { centers })
        },
        updateStateAccountType(obj) {
            this.updateShowSelectBoss(obj)
            let id = obj.id;
            if (id === GDKV || id === HANH_CHINH_KHU_VUC) {
                this.showKhuvuc = true;
            } else {
                this.showKhuvuc = false;
            }
            this.checkShowCenterAndBranch(obj);
            this.user.profile.account_type_id = id
            this.checkShowBusinessLevel(id);
        },
        updateStatePositions($event) {
            this.user.user_positions = []
            $event.map((value) => {
                this.positions.map((v) => {
                    if (value.position == v.position) {
                        this.user.user_positions.push({
                            position: v.id
                        })
                    }
                })
            })
        },
        updateStateMacaptren(obj) {
            this.user.parent_id = obj.id
        },
        updateStateBank(obj) {
            this.user.bank_accounts.ngan_hang = obj.id
        },
        async getAccountType() {
            await this.$store.dispatch(GET_ACCOUNT_TYPE_FOR_SELECT).then((res) => {
                if (!res.error) {
                    this.accountType = res.data
                }
            });
        },
        async getPositions() {
            await this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
                if (!res.error) {
                    this.positions = res.data
                }
            });
        },
        searchUserByBossCode(query) {
            this.isLoadingBoss = true;
            if (query.length > 0) {
                this.$store.dispatch(SEARCH_BY_MA_CAP_TREN, {
                    search_boss: query,
                    limit: 100,
                    branch_id: this.user.profile.chi_nhanh_id,
                    center_id: this.user.profile.trung_tam_id,
                    area_id: this.user.profile.khuvuc_id,
                }).then((res) => {
                    if (!res.error) {
                        this.maCapTren = res.data.data
                        this.isLoadingBoss = false;
                    }
                }).catch((error) => {
                    this.isLoadingBoss = false;
                });
            } else {
                this.isLoadingBoss = false;
            }
        },
        onSubmit() {
            if (this.user.backend_role_id != this.backend_role_id_default) {
                this.user.profile.user_update_role = this.currentUser.user.id;
                this.user.profile.updated_role_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
            }
            this.is_disable = true;
            let user = this.user
            this.$store
                .dispatch(UPDATE_STAFF, {
                    name: user.name,
                    id: user.id,
                    phone: user.phone,
                    status: user.status,
                    parent_id: user.parent_id,
                    password: user.password,
                    confirm_password: user.password,
                    positions: user.positions,
                    backend_role_id: user.backend_role_id,
                    profile: {
                        gender: user.profile.gender,
                        address: user.profile.address,
                        birthday: user.profile.birthday,
                        start_to_work: user.profile.start_to_work,
                        chi_nhanh_id: user.profile.chi_nhanh_id,
                        trung_tam_id: user.profile.trung_tam_id,
                        account_type_id: user.profile.account_type_id,
                        user_update_role: user.profile.user_update_role,
                        updated_role_at:user.profile.updated_role_at,
                        business_level_id: user.profile.business_level_id, 
                        khuvuc_id: user.profile.khuvuc_id
                    },
                })
                .then((res) => {
                    this.$router.push({name: 'account'}).then(() => {
                        this.$bvToast.toast('Cập nhật tài khoản thành công', {
                            title: 'Sửa tài khoản',
                            variant: 'success',
                            solid: true
                        });
                    })
                }).catch((e) => {
                if (e.data?.data?.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
                if (e.data?.message) {
                    this.$bvToast.toast(e.data.message, {
                        title: 'Tạo tài khoản',
                        variant: 'danger',
                        solid: true
                    });
                }
            }).finally(() => {
                this.is_disable = false;
            });
        },
        checkShowKhuVuc(user) {
            if (user.profile != null && user.profile.account_type != null) {
                if (user.profile.account_type.slug === 'gdkv' || user.profile.account_type.slug === 'hckv') {
                    this.showKhuvuc = true;
                    if (user.profile.area != null) {
                        this.cacheKhuvuc = user.profile.area
                    }
                } else this.showKhuvuc = false;
            }
        },
        handleCache(user) {
            if (user.profile != null && user.profile.account_type != null) {
                this.updateShowSelectBoss(user.profile.account_type)
                this.checkShowCenterAndBranch(user.profile.account_type)
            }

            this.cacheAccountType = user.profile.account_type
            this.cacheBranch = user.profile.branch
            this.cacheTrungTam = user.profile.center
            this.maCapTren = [user.user_boss]
            this.cachePosition = [];
            user.user_positions.map((value) => {
                this.cachePosition.push(value.position)
            })
        },
        setStateCenter($event) {
            this.cacheBranch = []
            this.user.profile.chi_nhanh_id = ''
            this.user.profile.trung_tam_id = $event.id
            this.getBranches()
        },
        setStateBranch($event) {
            this.user.profile.chi_nhanh_id = $event.id
        },
        activeTab(n) {
            if (n == 1) {
                this.tab_active1 = true
                this.tab_active2 = false
                this.show_active_1 = true
                this.show_active_2 = false
            } else {
                this.tab_active1 = false
                this.tab_active2 = true
                this.show_active_1 = false
                this.show_active_2 = true
            }
        },
        updateShowSelectBoss(obj) {
            if (obj.id === 1 || obj.id === 2 || obj.id === 3 || obj.id === 4) {
                this.showSelectBoss = true
            } else {
                this.showSelectBoss = false
            }
        },
        checkShowCenterAndBranch(obj) {
            let slug = obj.slug;
            if (slug === 'sale-leader' || slug === 'sale-expert' || slug === 'sale-member' || slug === 'sale-intern' || slug === 'gdvp' || slug === 'chi-nhanh' || slug === 'quan-ly-hoc-vien') {
                this.is_show_branch = true
            } else {
                this.is_show_branch = false
            }
            if (slug === 'tai-khoan-uy-quyen' || slug === 'chi-nhanh') {
                this.showTrungTam = true;
            } else {
                this.showTrungTam = false;
            }
            if (slug === 'gdkv' || slug === 'hckv') {
                this.showKhuvuc = true;
            } else {
                this.showKhuvuc = false;
            }
        },
        changePassword() {
            let id = this.$route.params.id;

            this.$store.dispatch(CHANGE_PASSWORD, {
                id: id,
                new_password: this.user.password,
                confirm_password: this.user.confirm_password,
            }).then((res) => {
                if (!res.error) {
                    this.$router.push({name: "account"}).then(() => {
                        this.$bvToast.toast(res.message, {
                            title: 'Đổi mật khẩu',
                            variant: 'success',
                            solid: true
                        });
                    })
                } else {
                    this.$bvToast.toast(res.message, {
                        title: 'Đổi mật khẩu',
                        variant: 'danger',
                        solid: true
                    });
                }
            }).catch((e) => {
                if (e.data.message.password) {
                    this.$bvToast.toast(e.data.message.password, {
                        title: 'Đổi mật khẩu',
                        variant: 'danger',
                        solid: true
                    });
                    this.$refs.form.setErrors(e.data.message.password);
                }
            });
        },
        getBusinessLevels() {
            this.$store.dispatch(GET_BUSINESS_LEVEL, {
                limit: 10000,
                status: 1,
                account_type_id: this.user.profile.account_type_id
            }).then((res) => {
                if (!res.error) {
                    this.businessLevels = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        checkShowBusinessLevel(id) {
            this.showBusinessLevel = false;
            if ([GDVP, GDKV, SALE_LEADER, SALE_MEMBER].includes(id)) {
                this.getBusinessLevels();
                this.showBusinessLevel = true;
                this.user.profile.business_level_id = this.user.profile.business_level_id > 0 ? this.user.profile.business_level_id : ''
            }
        }
    },
};
</script>
<style scoped>
.mx-datepicker {
    width: 100% !important;
}

.add-bank-account:hover i, .add-bank-account:hover .text-muted {
    color: #1BC5BD !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>

